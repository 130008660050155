

import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component({})
export default class PickerInputMixin extends Vue {
  @Prop() value: any;
  @Prop() multiple: any;
  @Prop() returnId: any;
  @Prop() defaultFirst: any;

  protected items: Array<any> = [];
  localeMultiple: boolean = this.isMultiple;
  localSelectedIds: Array<number> = [];
  localSelectedItems: Array<any> = [];

  get selectedId() {
    if (this.isMultiple || this.localeMultiple) {
      return this.localSelectedIds;
    }
    return this.localSelectedIds[0];
  }

  set selectedId(value) {
    if (this.isMultiple || Array.isArray(value)) {
      this.localeMultiple = true;
      //@ts-ignore
      this.localSelectedIds = value;
    } else {
      this.localSelectedIds = [value];
    }
  }

  get selectedItem() {
    if (this.isMultiple || this.localeMultiple) {
      return this.localSelectedItems;
    }
    return this.localSelectedItems[0];
  }

  set selectedItem(value) {
    if (this.isMultiple || Array.isArray(value)) {
      this.localeMultiple = true;
      this.localSelectedItems = value;
    } else {
      this.localSelectedItems = [value];
    }
  }

  get isMultiple() {
    return this.multiple || this.multiple === "";
  }

  get isReturnId() {
    return this.returnId || this.returnId === "";
  }

  get isDefaultFirst() {
    return this.defaultFirst || this.defaultFirst === "";
  }

  @Watch('items')
  onItemsChange() {
    this.update();
  }

  @Watch('value')
  onValueChange() {
    this.update();
  }

  mounted() {
    this.update();
  }


  update() {
    this.selectedId = this.value;
    if (this.value) {
      if (Array.isArray(this.value) && this.value.length > 0 && this.value[0].hasOwnProperty('id')) {
        this.selectedId = this.value.map(item => item.id);
      } else if (this.value.hasOwnProperty('id')) {
        this.selectedId = this.value.id;
      }
      this.localSelectedItems = this.getSelectedItems();
    } else {
      if(this.isDefaultFirst) {
        let firstRecordId = this.items ? this.items[0]?.id : null;
        if (firstRecordId) {
          this.selectedId = firstRecordId;
        }
      } else {
        this.localSelectedIds = [];
      }

      this.localSelectedItems = this.getSelectedItems();
      this.input();
    }
  }

  input() {
    this.localSelectedIds = this.getSelectedIds();
    if (this.isReturnId) {
      this.$emit('input', this.selectedId);
      this.$emit('value', this.selectedId);
    } else {
      this.$emit('input', this.selectedItem);
      this.$emit('value', this.selectedItem);
    }
  }

  getSelectedItems(): any {
    let selectedItems: Array<any> = [];
    if (this.items) {
      this.items.forEach((item) => {
        if (this.localSelectedIds.includes(item.id)) {
          selectedItems.push(item);
        }
      });
    }
    return selectedItems;
  }

  getSelectedIds(): any {
    let selectedIds: Array<number> = [];
    if (this.items) {
      this.items.forEach((item) => {
        this.localSelectedItems.forEach((selectedItem: any) => {
          if (selectedItem != null && selectedItem.id == item.id) {
            selectedIds.push(item.id);
          }
        });
      });
    }
    return selectedIds;
  }
}
